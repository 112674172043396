import * as React from 'react'
import * as styles from './PortfolioPage.module.scss'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'

import cn from 'classnames'

import { ImageFromPath } from 'utils'

import { Title, SubTitle } from 'ui'

import Images from './Images'
import SeeAlso from './SeeAlso'
import Contact from './Contact'
import BreadCrumb from 'components/BreadCrumb'

import { isMobile } from 'react-device-detect'

const PortfolioPage = ({ location, data }) => {
  const frontmatter = data.markdownRemark.frontmatter

  return (
    <Layout
      titleClassName={styles.mainTitle}
      ExtraTopComponent={
        <BreadCrumb
          linkTo="/portfolio"
          linkTitle="Portfolio"
          title={frontmatter.title}
        />
      }
      location={location}
      title={frontmatter.title}
      subTitle={frontmatter.description}>
      <SEO
        title={frontmatter.meta_title || frontmatter.title}
        description={frontmatter.meta_description || frontmatter.description}
        location={location.href}
        image_url={frontmatter.main_image?.publicURL}
      />
      <ImageFromPath
        url={frontmatter.main_image.publicURL}
        alt="Cover Image"
        className={styles.mainImage}
      />
      <div className={cn('container', styles.content)}>
        <ul className={styles.content__paragraphs}>
          {frontmatter.paragraphs.map(({ title, text }, i) => (
            <li
              className={styles.content__paragraphs__item}
              key={`paragraphs-item-${i}`}>
              <Title text={title} />
              <SubTitle text={text} variant="small" />
            </li>
          ))}
        </ul>
        <ul className={styles.content__info}>
          <li className={styles.content__info__item}>
            <SubTitle
              text={frontmatter.tags_title}
              className={styles.content__info__item__title}
            />
            <SubTitle
              text={frontmatter.tags.join(', ')}
              variant="small"
              className={styles.content__info__item__description}
            />
          </li>
          <li className={styles.content__info__item}>
            <SubTitle
              text="Working time"
              className={styles.content__info__item__title}
            />
            <SubTitle
              text={frontmatter.working_time}
              variant="small"
              className={styles.content__info__item__description}
            />
          </li>
          <li className={styles.content__info__item}>
            <SubTitle
              text="Industry"
              className={styles.content__info__item__title}
            />
            <SubTitle
              text={frontmatter.industry}
              variant="small"
              className={styles.content__info__item__description}
            />
          </li>
          {frontmatter.case_study_url && (
            <li className={styles.content__info__item}>
              <SubTitle
                text="Case study"
                className={styles.content__info__item__title}
              />
              <a href={frontmatter.case_study_url} target="_blank">
                <SubTitle
                  text={frontmatter.case_study_name}
                  variant="small"
                  className={cn(
                    styles.content__info__item__description,
                    styles.linkText,
                  )}
                />
              </a>
            </li>
          )}
          {frontmatter.website_url && (
            <li className={styles.content__info__item}>
              <SubTitle
                text="Website"
                className={styles.content__info__item__title}
              />
              <a href={frontmatter.website_url} target="_blank">
                <SubTitle
                  text={frontmatter.website_name}
                  variant="small"
                  className={cn(
                    styles.content__info__item__description,
                    styles.linkText,
                  )}
                />
              </a>
            </li>
          )}
        </ul>
      </div>
      <Images
        images_large={frontmatter.images_large}
        images_small={frontmatter.images_small}
      />
      {!isMobile && (
        <SeeAlso
          allNodes={data.allMarkdownRemark.nodes}
          currentNodeId={data.markdownRemark.id}
        />
      )}
      <Contact />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PortfolioItemQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        main_image {
          publicURL
        }
        images_large {
          sort_index
          image {
            publicURL
          }
        }
        images_small {
          sort_index
          image {
            publicURL
          }
        }
        paragraphs {
          text
          title
        }
        tags_title
        tags
        working_time
        website_url
        website_name
        industry
        case_study_name
        case_study_url
        meta_title
        meta_description
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(portfolio)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          pages_to_show
          preview_image {
            publicURL
          }
          tags
          industry
        }
      }
    }
  }
`

export default PortfolioPage
