// extracted by mini-css-extract-plugin
export var content = "PortfolioPage-module--content--WGonH";
export var content__info = "PortfolioPage-module--content__info--FZ2DA";
export var content__info__item = "PortfolioPage-module--content__info__item--yZEBA";
export var content__info__item__description = "PortfolioPage-module--content__info__item__description--aQjXl";
export var content__info__item__title = "PortfolioPage-module--content__info__item__title--fmDW1";
export var content__paragraphs = "PortfolioPage-module--content__paragraphs--kWoSp";
export var content__paragraphs__item = "PortfolioPage-module--content__paragraphs__item--fdxy-";
export var linkText = "PortfolioPage-module--linkText--fdjqq";
export var mainImage = "PortfolioPage-module--mainImage--7WgUZ";
export var mainTitle = "PortfolioPage-module--mainTitle--lGhcZ";