import React, { useEffect, useState } from 'react'

import * as styles from './Images.module.scss'

import cn from 'classnames'

import List from './List'

const sortImages = (images_large = [], images_small = []) => {
  const largeImages = images_large.map(props => ({ ...props, isLarge: true }))
  const smallImages = images_small.map(props => ({
    ...props,
    isLarge: false,
  }))
  const allImages = [...largeImages, ...smallImages]
  const sortedImages = []
  for (let i = 0; i < allImages.length; i++) {
    const element = allImages[i]
    sortedImages[element.sort_index - 1] = element
  }
  return sortedImages
}

const Images = ({ images_large, images_small }) => {
  const [images, setImages] = useState([])

  useEffect(() => {
    if (!images_large && !images_small) return
    setImages(sortImages(images_large, images_small))
  }, [])
  return (
    <div className={cn('container', styles.container)}>
      <List images={images} />
    </div>
  )
}

export default Images
