import React, { useMemo } from 'react'
import * as styles from './SeeAlso.module.scss'
import cn from 'classnames'

import { Title } from 'ui'
import PortfolioItem from 'components/Portfolio/PortfolioItem'

import { getRandomItemFromArray } from 'utils'

const SeeAlso = ({ allNodes, currentNodeId }) => {
  const randomNodes = useMemo(() => {
    let nodesFiltered = allNodes.filter(({ id }) => id !== currentNodeId)
    const randomItem = getRandomItemFromArray(nodesFiltered)
    nodesFiltered = nodesFiltered.filter(({ id }) => id !== randomItem.id)
    const randomItemSecond = getRandomItemFromArray(nodesFiltered)
    if (randomItem && randomItemSecond) {
      return [randomItem, randomItemSecond]
    } else if (randomItem) {
      return [randomItem]
    } else {
      return []
    }
  }, [allNodes, currentNodeId])

  return (
    <div className={cn('container', styles.container)}>
      <Title text="See Also" className="text-center" />
      <ul className={styles.container__list}>
        {randomNodes.map(({ frontmatter, fields: { slug }, id }) => (
          <PortfolioItem
            {...frontmatter}
            linkTo={`/portfolio${slug}`}
            key={id}
          />
        ))}
      </ul>
    </div>
  )
}

export default SeeAlso
