import React, { memo, useState } from 'react'
import cn from 'classnames'

import * as styles from './Contact.module.scss'

import { Title, DefaultButton as Button } from 'ui'

import Modal from 'components/Modal'
import Form from 'components/FrontPage/FeedbackSection/Form/Form'

const Contact = memo(() => {
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const handleOpenModal = () => setmodalIsOpen(true)
  const handleCloseModal = () => setmodalIsOpen(false)

  return (
    <>
      <div className={cn('container', styles.container)}>
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title
            text="Are you looking for professionals to make you a super design?"
            className={styles.container__title}
          />
          <div className={styles.container__buttonWrap}>
            <Button
              title="Click here to start"
              type="button"
              className={styles.container__buttonWrap__button}
              onClick={handleOpenModal}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <Form isModal afterSubmit={handleCloseModal} />
      </Modal>
    </>
  )
})

export default Contact
