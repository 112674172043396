import React from 'react'

import * as styles from './List.module.scss'

import { ImageFromPath } from 'utils'

const List = ({ images }) => {
  return (
    <div className={styles.container}>
      {images.map(({ image: { publicURL }, sort_index, isLarge }, i, array) => {
        if (array[i - 1] && !array[i - 1]?.isLarge === false) {
          return null
        }
        return isLarge ? (
          <div
            className={styles.container__big}
            key={`images-image-${publicURL}-${i}`}>
            <ImageFromPath url={publicURL} alt={'title'} />
          </div>
        ) : (
          <div
            className={styles.container__small}
            key={`images-image-${publicURL}-${i}`}>
            <div className={styles.container__small__item}>
              {array[i - 1]?.image?.publicURL && (
                <ImageFromPath
                  url={array[i - 1].image.publicURL}
                  alt={'title'}
                />
              )}
            </div>
            <div className={styles.container__small__item}>
              <ImageFromPath url={publicURL} alt={'title'} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default List
